/**
 * This component is a Delete modal for user to proceed with event like remove for favorites, prouduct, etc.
 *
 * @module views/components/RemoveItem
 * @memberof -Common
 */
import './RemoveItem.scss';

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import Button from '@ulta/core/components/Button/Button';
import Text from '@ulta/core/components/Text/Text';
import useLoader from '@ulta/core/hooks/useLoader/useLoader';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { hasItems } from '@ulta/core/utils/array/array';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';

import BlockMessage from '../BlockMessage/BlockMessage';
import * as utils from './RemoveItem';


/**
  * Represents a confirmation modal component
  *
  * @method
  * @param {RemoveItemProps} props - React properties passed from composition
  * @returns RemoveItem
  */
export const RemoveItem = ( props ) => {
  const {
    headerText,
    confirmMessage,
    confirmLabel,
    cancelLabel,
    action,
    invokeMutation,
    renderComponent,
    moduleParams,
    contentId,
    loading,
    messages,
    componentKey
  } = props;

  const [loader, showLoader, hideLoader] = useLoader( {} );
  const [closeModal, setcloseModal] = useState( false );

  useEffect( () => {
    loading ? showLoader() : hideLoader();
  }, [loading] );

  const { closeOverlay } = useOverlay();

  useEffect( () => {
    if( closeModal === true && ( !hasItems( messages ) ) ){
      closeOverlay( { userCancelled: true } );
    }
  }, [closeModal] );

  if( !headerText || !confirmMessage || !confirmLabel ){
    return null;
  }
  return (
    <div className='RemoveItem'>
      <div className='RemoveItem__contentBlock'>
        { loader }

        { hasItems( messages ) &&
          messages.map( ( data, index ) => (
            <div className='RemoveItem__blockErrorMessage'
              key={ `${index}-${componentKey}` }
            >
              <BlockMessage
                message={ data.message }
                messageType={ data.icon }
                backgroundColor={ data.backgroundColor }
                borderColor={ data.borderColor }
                textColor={ data.textColor }
                dismissAction={ data.dismissAction }
                action1={ data.action1 }
                action1Style='link'
                action2={ data.action2 }
                role={ data.role }
              />
            </div>
          ) ) }

        <Text htmlTag='p'
          textStyle='body-2'
          color='black'
        >
          { headerText }
        </Text>
        { renderComponent &&
          <AsyncComponent
            { ...renderComponent }
          />
        }

        <div className='confirmMessage'>
          <Text htmlTag='span'
            textStyle='body-2'
            color='black'
          >
            { confirmMessage }
          </Text>
        </div>
        <div className='RemoveItem__actions'>
          <Button
            secondary
            compact
            withHover
            action={ action }
            label={ confirmLabel }
            onClick={ () => {
              utils.composeRemove(
                { action: action, moduleParams, contentId },
                { invokeMutation, setcloseModal } );
            } }
          />
          <Button
            likeLink
            label={ cancelLabel }
            onClick={ () => closeOverlay( { userCancelled: true } ) }
          />
        </div>
      </div>
    </div>
  );
};

/**
 * composeRemove function for toggle button
 * @method
 * @param {Object} methods - Passing invokeMutation method as an argument
 */

export const composeRemove = ( data, methods ) => {
  const { action, moduleParams, contentId } = data || {};
  const { invokeMutation, setcloseModal } = methods || {};
  const { graphql, params, customHeaders } = action || {};

  if( !graphql ){
    return;
  }

  invokeMutation( {
    graphql,
    customHeaders,
    params,
    variables: {
      moduleParams: moduleParams,
      url: { path: !isServer() && global.location.href },
      ...( contentId && { contentId } )
    }
  } );
  setcloseModal( true );

};

/**
  * Property type definitions
  * @typedef RemoveItemProps
  * @type {object}
  * @property {string} headerText - Set the header text.
  * @property {string} confirmMessage - Set the confirm Message.
  * @property {string} confirmLabel - Set the confirm Label.
  * @property {string} cancelLabel - set the cancel Label.
  * @property {object} action - Set the remove action
  * @property {function} invokeMutation - Set the invoke Mutation
  * @property {object} renderComponent - Set the child component
  * @property {object} moduleParams - Set the mutation module Params
  * @property {number} contentId - Set the content Id
  * @property {boolean} loading - Set the loading
  * @property {array} messages - Set the messages
  */
export const propTypes = {
  headerText: PropTypes.string,
  confirmMessage: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  action: PropTypes.object,
  invokeMutation: PropTypes.func,
  renderComponent: PropTypes.object,
  moduleParams: PropTypes.object,
  contentId: PropTypes.number,
  loading: PropTypes.bool,
  messages: PropTypes.oneOfType([PropTypes.array,PropTypes.object]),
  componentKey: PropTypes.number
};

RemoveItem.propTypes = propTypes;
export default RemoveItem;