/**
 * Use item thumbnail images to represent an item or items in a compact way, when minimal information is needed.
 *
 * @module views/components/BagItemThumbNail
 * @memberof -Common
 */
import './BagItemThumbNail.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';

import StatusBadges from '../StatusBadges/StatusBadges';
/**
 * Represents a BagItemThumbNail component
 *
 * @method
 * @param {BagItemThumbNailProps} props - React properties passed from composition
 * @returns BagItemThumbNail
 */
export const BagItemThumbNail = ( props ) => {

  const { image, quantity, quantityLabel, handleMoreItem, thumbSize, ariaLabel, showBackground } = props;
  const enableQuantityButton = !!quantityLabel && !!handleMoreItem;

  return (
    <div className={ classNames( 'BagItemThumbNail', {
      [ `BagItemThumbNail--${ thumbSize }` ]: thumbSize,
      [ `BagItemThumbNail--ShowBackground` ]: showBackground
    } ) }
    >
      { image &&
        <UltaAsset { ... ( image ) }
          width={ 48 }
        />
      }
      { ( quantity > 1 ) &&
        <div className='BagItemThumbNail__Badge'>
          <StatusBadges
            badgeValue={ quantity }
            badgeType='quantity'
          />
        </div>
      }
      { enableQuantityButton &&
        <div className='BagItemThumbNail__Link'>
          <Button
            likeLink
            label={ quantityLabel }
            onClick={ handleMoreItem }
            ariaLabel={ ariaLabel }
          />
        </div>
      }
      { !enableQuantityButton &&
      <div className='BagItemThumbNail__ItemCount'>
        <Text>
          { quantityLabel }
        </Text>
      </div>
      }
    </div>
  );
};

/**
 * Property type definitions
 * @typedef BagItemThumbNailProps
 * @type {object}
 * @property {object} image - image url
 * @property {number} quantity - quantity value
 * @property {string} quantityLabel - number of items to be expanded
 * @property {string} ariaLabel - Sets the label to be read by assistive technology
 * @property {function} handleMoreItem - function that used for expandable purpose of item
 * @property {boolean} showBackground - Set the default boolean value for showBackground
 * @property {string} thumbSize - Set the default thumbnail size
 */

export const propTypes =  {
  image: PropTypes.oneOfType( [PropTypes.string, PropTypes.object] ),
  quantity: PropTypes.oneOfType( [PropTypes.string, PropTypes.number] ),
  quantityLabel: PropTypes.string,
  ariaLabel: PropTypes.string,
  handleMoreItem: PropTypes.func,
  showBackground: PropTypes.bool,
  thumbSize:PropTypes.string
};
/**
 * Default values for passed properties
 * @type {object}
 * @property {boolean} showBackground=true - Set the default boolean value for showBackground
 * @property {string} thumbSize='sm' - Set the default thumbnail size
 */
export const defaultProps =  {
  showBackground: true,
  thumbSize: 'sm'
};
BagItemThumbNail.propTypes = propTypes;
BagItemThumbNail.defaultProps = defaultProps;

export default BagItemThumbNail;
